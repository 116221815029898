"use client";

import { useState, forwardRef } from "react";
import type { ElementRef, ComponentPropsWithoutRef } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { SignedIn } from "@clerk/nextjs";
import { Menu } from "lucide-react";
import { usePostHog } from "posthog-js/react";

import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";

const menuItems = [
  { title: "Online Schulungen", href: "/online-kurse" },
  { title: "Wissensplattform", href: "/blog" },
  { title: "Über uns", href: "/about" },
  { title: "HCP Programm", href: "/partner/hcp" },
  { title: "B2B Kooperationen", href: "/partner/b2b" },
];

const Navigation = () => {
  const posthog = usePostHog();
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();

  const isCurrent = (href: string) => href.replace(/\/$/, "") === pathname;

  return (
    <>
      <div className="hidden lg:block">
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <Link href="/online-kurse" legacyBehavior passHref>
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent text-base",
                    {
                      ["bg-sun-100"]: isCurrent("/online-kurse"),
                    }
                  )}
                >
                  Online Schulungen
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link href="/blog" legacyBehavior passHref>
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent text-base",
                    {
                      ["bg-sun-100"]: isCurrent("/wissen"),
                    }
                  )}
                >
                  Wissensplattform
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link href="/about" legacyBehavior passHref>
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent text-base",
                    {
                      ["bg-sun-100"]: isCurrent("/about"),
                    }
                  )}
                >
                  Über uns
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuTrigger
                className={cn("bg-transparent text-base", {
                  ["bg-white"]:
                    isCurrent("/partner/b2b") || isCurrent("/partner/hcp"),
                })}
              >
                Partner werden
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px]">
                  <ListItem href="/partner/hcp" title="HCP">
                    HCP Program
                  </ListItem>
                  <ListItem href="/partner/b2b" title="B2B">
                    B2B Kooperationen
                  </ListItem>
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>

      {/* Mobile Menu */}
      <div className="lg:hidden order-last pl-1">
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <Button variant="ghost" size="icon">
              <Menu className="h-6 w-6" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="right">
            <nav className="flex flex-col space-y-4">
              {menuItems.map((item) => (
                <Link
                  key={item.title}
                  href={item.href}
                  className="text-lg font-medium text-primary hover:text-primary/80"
                  onClick={() => setIsOpen(false)}
                >
                  {item.title}
                </Link>
              ))}
              <SignedIn>
                <Separator className="my-4" />
                <Link href="/library" onClick={() => setIsOpen(false)}>
                  <button className="bg-black text-white px-4 py-2 rounded-full h-10 items-center">
                    Zu den Kursen
                  </button>
                </Link>
              </SignedIn>
            </nav>
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};

const ListItem = forwardRef<ElementRef<"a">, ComponentPropsWithoutRef<"a">>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="text-base font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    );
  }
);

ListItem.displayName = "ListItem";

export { Navigation };
